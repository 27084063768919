import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter, Link} from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Breadcrumb from './components/breadcrumb';
import { withRouter } from "react-router";
import PropTypes from "prop-types";

const allroutes = [
  { to: '/', label: 'Home' },
  { to: '/about', label: 'About' },
  { to: '/contact', label: 'Contact' },
  { to: '/about/history', label: 'History' },
  { to: '/about/present', label: 'Present' },
  { to: '/contact/contact_level1', label: 'Contact_Level1' },
  { to: '/contact/contact_level1/contact_level2', label: 'Contact_Level2' },
  { to: '/contact/contact_level1/contact_level2/contact_level3', label: 'Contact_Level3' },
  { to: '*', label: 'Route_Oops' },
]

// class ShowTheLocation extends React.Component {
//   static propTypes = {
//     match: PropTypes.object.isRequired,
//     location: PropTypes.object.isRequired,
//     history: PropTypes.object.isRequired
//   };
//   render() {
//     const { match, location, history } = this.props;       
    
//   return <div>location: {location.pathname} </div>
//   }
// }

class ShowTheBreadCrumbs extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };
  render() {
    const { match, location, history } = this.props;
    
    var pathname = location.pathname;
    var breadcrumbsarray = [{ to: '', label: '' }];
    var breadcrumbselements = "";
    
    breadcrumbsarray.push({to: '/', label: 'Home'});
    if(pathname != "/"){
      var pathname_nohome = pathname.substring(1);
      var pathsfrompath = pathname_nohome.split("/");
      for(var i = 0; i < pathsfrompath.length; i++){  
        breadcrumbselements += ("/" + pathsfrompath[i]);      
        for(var j = 0; j < allroutes.length; j++){          
          if(breadcrumbselements == allroutes[j].to){
            breadcrumbsarray.push({ to: allroutes[j].to, label: allroutes[j].label });
            j = allroutes.length;
          }
        }
      }
    }
  
  return <Breadcrumb>
          {breadcrumbsarray.map(({ to, label }) => (
              <Link key={to} to={to}>
                {label}
              </Link>
            ))} 
        </Breadcrumb>
  }
}

//const ShowTheLocationWithRouter = withRouter(ShowTheLocation);
const ShowTheBreadCrumbsWithRouter = withRouter(ShowTheBreadCrumbs);

ReactDOM.render(
  <React.StrictMode>    
    <BrowserRouter>       
      <ShowTheBreadCrumbsWithRouter/>
      {/* <Breadcrumb>
        {allroutes.map(({ to, label }) => (
            <Link key={to} to={to}>
              {label}
            </Link>
          ))} 
      </Breadcrumb> */}
      {/* <ShowTheLocationWithRouter />         */}
      <App />
    </BrowserRouter>    
  </React.StrictMode>,
  document.getElementById('root')  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
