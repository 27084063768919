import React, {Component} from 'react';
import '../App.css';

function History() {
    return (
      <div className="App2">
        <header className="App-header2">          
          <p>
            "This is the history of the About page."
          </p>          
        </header>
      </div>
    )
  }
export default History;