import React from 'react';
import '../App.css';

function SignOut() {
  return (
    <div className="SignOut">
      <header className="SignOut-Header">          
        <p>
          "You have been signed out of lfg.ninja."
        </p>        
      </header>
    </div>
  )
}
export default SignOut;