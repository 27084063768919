import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import logo from '../logo.svg';
import '../App.css';
import Breadcrumb from './breadcrumb';

// Using the array and breadcrumb in the div makes this work on the page,
// but thinking it's too much to manage for each page.
// const items = [
//   { to: '/', label: 'Home' },
//   { to: '/contact', label: 'Contact' },
//   { to: '/Contact/Contact_Level1', label: 'Contact Level 1' },
//   { to: '/Contact/Contact_Level1/Contact_Level2', label: 'Contact Level 2' },
//   { to: '/Contact/Contact_Level1/Contact_Level2/Contact_Level3', label: 'Contact Level 3' },
// ]

function Contact_Level3() {
  return (
    <div className="App2">
      {/* <div>
        "This code is on the individual page"          
        <Breadcrumb>
          {items.map(({ to, label }) => (
            <Link key={to} to={to}>
              {label}
            </Link>
          ))}
        </Breadcrumb>
      </div> */}
      <header className="App-header2">          
        <p>
          "This is level 3 of the contact page."
        </p>          
      </header>
    </div>
  )
}
export default Contact_Level3;