import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import '../App.css';

function Contact_Level2() {
  return (
    <div className="App2">
      <header className="App-header2">          
        <p>
          "This is level 2 of the contact page."
        </p>
        <ul>
          <li>
            <Link to='/contact/contact_level1/contact_level2/contact_level3'>Contact_Level3</Link>
          </li>            
        </ul>          
      </header>
    </div>
  )
}
export default Contact_Level2;