import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import Contact from './contact';
import Contact_Level1 from './contact_level1';
import Contact_Level2 from './contact_level2';
import Contact_Level3 from './contact_level3';
import History from './history';
import Present from './present';
import About from './about';
import Home from './home';
import Route_Oops from './route_oops';
import DashboardUser from './dashboarduser';
import SignOut from './signout';

const Routes = () => {
    return (
        <div>                        
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/about">
                    <About />
                </Route>
                <Route exact path="/contact">
                    <Contact />
                </Route>
                <Route exact path="/about/history">
                    <History />
                </Route>
                <Route exact path="/about/present">
                    <Present />
                </Route>
                <Route exact path="/contact/contact_level1">
                    <Contact_Level1 />
                </Route>
                <Route exact path="/contact/contact_level1/contact_level2">
                    <Contact_Level2 />
                </Route>
                <Route exact path="/contact/contact_level1/contact_level2/contact_level3">
                    <Contact_Level3 />
                </Route>
                <Route exact path="/dashboarduser">
                    <DashboardUser />
                </Route>
                <Route exact path="/signout">
                    <SignOut />
                </Route>
                <Route path="*">
                    <Route_Oops />
                </Route>
            </Switch>
        </div>    
    );
}

//BEGIN WORKS 20201116_1843
// const Routes = () => {
//     return (        
//         <Switch>
//             <Route exact path="/" component={Home} />
//             <Route exact path="/about" component={About} /> 
//             <Route exact path="/contact" component={Contact} />
//             <Route exact path="/about/history" component={History} />
//             <Route exact path="/about/present" component={Present} /> 
//             <Route exact path="/contact/contact_level1" component={Contact_Level1} />
//             <Route exact path="/contact/contact_level1/contact_level2" component={Contact_Level2} />
//             <Route exact path="/contact/contact_level1/contact_level2/contact_level3" component={Contact_Level3} />   
//             <Route path="*" component={Route_Oops}/>     
//         </Switch>
//     );    
// }
//END WORKS 20201116_1843

export default Routes;

//BEGIN DOESNT WORK 2020116_1928
// const routesarray = [
//     {
//       path: "/",
//       component: Home,
//       crumblycrumbs: "MyCrumbies"
//     },
//     {
//         path: "/about",
//         component: About
//     },
//     {
//         path: "/contact",
//         component: Contact
//     }
//   ];

//   const Routes = () => {
//     return (
//     //   <Router>
//     //     <div>          
//           <Switch>
//             {routesarray.map((route, i) => (
//               <RouteWithSubRoutes key={i} {...route} />
//             ))}
//           </Switch>
//     //     </div>
//     //   </Router>
//     );
//   }

//   function RouteWithSubRoutes(route) {
//     return (
//       <Route
//         path={route.path}
//         render={props => (
//           // pass the sub-routes down to keep nesting
//           <route.component {...props} routes={route.routes} />
//         )}
//       />
//     );
//   }
//END DOESNT WORK 20201116_1928
