import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import '../App.css';

function Contact_Level1() {
  return (
    <div className="App2">
      <header className="App-header2">
      <p>"This is contact page Level 1"
      </p>          
      <ul>
        <li>
          <Link to='/contact/contact_level1/contact_level2'>Contact_Level2</Link>
        </li>            
      </ul>
      </header>
    </div>
  )
}
export default Contact_Level1;