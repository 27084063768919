import React, {Component} from 'react';
//import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import logo from '../logo.svg';
import '../App.css';

function Route_Oops() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Oops you landed here by mistake!
          </p>
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>          
        </header>
      </div>
    )
  }
export default Route_Oops;