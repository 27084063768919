import React from 'react';
import '../App.css';

function DashboardUser() {
  return (
    <div className="UserDashboard">
      <header className="UserDashboard-Header">          
        <p>
          "This page should only be available after being authenticated."
        </p>        
      </header>
    </div>
  )
}
export default DashboardUser;