import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import './App.css';
import Routes from './components/routes';
import Breadcrumb from './components/breadcrumb';

const items = [
  { to: '/dashboard', label: 'Dashboard' },
  { to: '/blog', label: 'Blog' },
]

function App() {
  return (
    <Routes />
  );
}
export default App;

